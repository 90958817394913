import React from "react"
import Seo from "../../../components/Seo/Seo"
import "./standard-deviation.sass"
import { StaticImage } from "gatsby-plugin-image"
import PageTransition from "../../../components/PageTransition"
import PageHeader from "../../../components/PageHeader"
import LayoutLiveStream from "../../../components/LayoutLiveStream"

const AboutPage = (props) => {

	return (

		<PageTransition {...props}>

			<Seo 
				title="About Live Streams"
				description='Deviant Robot love streams all about ideas, culture and conversations.'
				pathname="/about/live-streams"/>

			<div className="page-live-streams">

				<PageHeader title="Deviant Robot live streams" description="About our shows, how they work and how to get involved" />

				<LayoutLiveStream>
	
					<h2>About Our Streams</h2>

					<h3>Standard Deviation</h3>
					<span>A weekly show every Wednesday, discussing ideas, culture and engaging in banter.</span>
			
					<h3>Casual Conversations</h3>
					<span>A relaxed conversation with a member of our community, discussing life experiences and ideas about the world in general.</span>
		
					<h3>Deviant Discourse</h3>
					<span>An interview with interesting people who have had unusual experience hold unique perspectives or have deep expertise in an interesting subject area.</span>
				
				
					<h3>Deviant Minds</h3>
					<span>A group discussion around a certain topic. </span>
				
		
				</LayoutLiveStream>

			</div>

		</PageTransition >

	)

}
export default AboutPage
