import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import * as style from "./LayoutLiveStream.module.sass"

const LayoutLiveStream = (props) => {

	const { children } = props

	console.info(style)

	return (

		<div className={style.LayoutLiveStream}>

			<nav>

				<Link>
					<h3>Live streams overview</h3>
				</Link>

				<h2>Additional information</h2>

				<Link>
					<h3>How we stream</h3>
					<span>Find out about the process behind our live streams, as well as the tools and software we use.</span>
				</Link>

				<Link>
					<h3>How to become a guest</h3>
					<span>If you think you would make a great guest on our live streams, please get in touch.</span>
				</Link>

				<Link>
					<h3>Information for guests</h3>
					<span>Useful information for guests; useful tips on how we work and how to get the best experience. </span>
				</Link>

				<Link>
					<h3>Stream community guide</h3>
					<span>How to be a part of the Deviant Robot live stream community. Also tips on not getting banned :D</span>
				</Link>
				
			</nav>

			<main>

				{children}

			</main>

		</div>

	)

}

export default LayoutLiveStream
